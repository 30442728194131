<template>
    <div class="main">
        <img class="top-img" src="../../assets/image/top-img.png"/>
        <div class="top-text-box">
            <div class="top-text">和优秀的人</div>
            <div class="top-text">做<span class="top-text-color">有挑战</span>的事</div>
        </div>
        <div class="content-box">
            <div class="search-box">
                <van-search
                        v-model="listQuery.jobName"
                        shape="round"
                        placeholder="请输入搜索关键词"
                        @input="getList"
                />
            </div>
            <div class="select-box">
                <div class="select-box-item">
                    <div style="width: 80px"  @click="showBox = true">{{typeName}}</div>
                    <img v-if="typeName === '岗位类别'" class="select-box-item-img" src="../../assets/image/arrow-down.png"/>
                    <img  @click="closeType" v-else class="select-box-item-img" src="../../assets/image/close.png"/>
                </div>
                <div class="select-box-item">
                    <div style="width: 80px" @click="show = true">{{cityName}}</div>
                    <img v-if="cityName === '城市'" class="select-box-item-img" src="../../assets/image/arrow-down.png"/>
                    <img  @click="closeCity" v-else class="select-box-item-img" src="../../assets/image/close.png"/>
                </div>
            </div>
            <div class="list-box">
                <div @click="toInfo(item)" v-for="(item,id) in list" v-bind:key="id" class="list-item">
                    <div class="list-item-top">
                        <span class="list-item-top-title">{{item.jobName}}</span>
                        <span class="list-item-top-num">{{item.numNeed}}人</span>
                    </div>
                    <div class="list-item-bottom">
                        <span>{{item.provinceName}}/{{item.cityName}}</span>
                        <span style="padding-left: 10px">截止：{{item.recruitEnd}}</span>
                    </div>
                </div>
            </div>
            <div class="bottom-blank"></div>
        </div>
        <div class="bottom-blank"></div>
        <!--选择岗位开始-->
        <div class="select-type-box" v-if="showBox">
            <van-picker
                    title="请选择岗位"
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
            />
        </div>
        <!--选择岗位结束-->
        <!--选择城市开始-->
        <van-popup v-model="show" round position="bottom">
            <van-cascader
                    v-model="cascaderValue"
                    title="请选择所在地区"
                    :options="options"
                    @close="show = false"
                    :field-names="fieldNames"
                    @finish="onFinish"
            />
        </van-popup>
        <!--选择城市结束-->
    </div>
</template>

<script>
    import { getSearchInfo, getStationList } from '@/api/service'
    import { Toast } from 'vant';
    export default {
        name: 'Recruit',
        components: {
        },
        data() {
            return {
                userId: '', // 用户id
                nowUserInfo: {}, // 当前用户信息
                query: '',
                list: {},
                typeName: '岗位类别',
                cityName: '城市',
                listQuery: {
                    jobName: '',
                    taskId: '',
                    jobId: '',
                    cityId: '',
                    page: '',
                    pageSize: ''
                },
                showBox: false,
                columns: [],
                show: false,
                fieldValue: '',
                cascaderValue: '',
                // 选项列表，children 代表子选项，支持多级嵌套
                options: [],
                fieldNames: {
                    text: 'cityName',
                    value: 'cityId',
                    children: 'child',
                }
            }},
        created() {
            const query = this.$route.query
            if(!query.taskId){
                Toast('参数缺失')
            }
            this.listQuery.taskId = query.taskId
            this.query = query
            this.getSearch()
            this.getList()
        },
        mounted:function(){

        },
        methods: {
            // 清除type
            closeType(){
                this.typeName ='岗位类别'
                this.listQuery.jobId = ''
                this.getList()
            },
            // 清除城市
            closeCity(){
                this.cityName='城市'
                this.listQuery.cityId = ''
                this.getList()
            },
            // 全部选项选择完毕后，会触发 finish 事件
            onFinish({ selectedOptions }) {
                this.show = false;
                this.fieldValue = selectedOptions.map((option) => option.cityName).join('/');
                this.listQuery.cityId =selectedOptions[selectedOptions.length-1].cityId
                this.cityName = selectedOptions[selectedOptions.length-1].cityName
                this.getList()
            },
            onConfirm(value) {
                this.typeName = value
                // 获取id
                for(let i = 0;i < this.nowData.job.length; i++){
                    console.log(this.nowData.job[i].jobName)
                    if(this.nowData.job[i].jobName === value){
                        this.listQuery.jobId = this.nowData.job[i].jobId
                    }
                }
                this.getList()
                this.showBox = false
            },
            onCancel() {
                this.showBox = false
            },
            getList(){
                getStationList(this.listQuery).then((response) => {
                    this.list = response.data
                })
            },
            // 获取搜索信息
            getSearch() {
                const postData = {}
                postData.taskId = this.query.taskId
                getSearchInfo(postData).then((response) => {
                    for(let i = 0;i < response.data.job.length; i++){
                        this.columns.push(response.data.job[i].jobName)
                    }
                    this.options = response.data.city
                    this.nowData = response.data
                })
            },
            // 跳转到详情
            toInfo(item) {
                item.taskId = this.query.taskId
                item.shareId = this.query.shareId
                this.$router.push({ path: '/show', query: item})
            }
        }
    }
</script>
<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        background-color:#1243D5;
        background: url('../../assets/image/bj.jpg')  no-repeat center center fixed ;
        background-size: cover;
        box-sizing: border-box;
        position: relative;
    }
    .top-img{
        position: absolute;
        right: 0;
        top: 50px;
        width: 100px;
        height: 80px;
    }
    .top-text-box{
        font-size: 45px;
        color: #FFFFFF;
        font-weight: bold;
        padding: 30px 0 0 20px;
    }
    .top-text-color{
        color: #FFBF00;
    }
    .content-box{
        background-color: #ffffff;
        border-radius: 10px;
        width: calc(100vw - 40px);
        margin: 20px 20px 0 20px;
    }
    .bottom-blank{
        width: 100%;
        height: 30px;
    }
    .search-box{
        padding-top: 10px;
    }
    .select-box{
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
    }
    .select-box-item{
        width: 120px;
        height: 39px;
        line-height: 39px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E8E8E8;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .select-box-item-img{
        width: 20px;
        height: 20px;
    }
    .select-type-box{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
    }
    .list-box{
        padding: 20px;
    }
    .list-item{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 15px 0;
    }
    .list-item-top{
        display: flex;
        justify-content: space-between;
    }
    .list-item-top-title{
        font-size: 16px;
        font-weight: bold;
    }
    .list-item-top-num{
        font-size: 16px;
        color: #f6404f;
    }
    .list-item-bottom{
        color: #C0C1CC;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
</style>
